import React from "react";
import Layout from "../../components/layout";
import { Container, H1, NextStep, StepIndicator } from "../../styles/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Contact from "../../components/contact";
import Card from "../../components/card";
import { FiCheckCircle } from "react-icons/fi";

const SzemelyreSzabottDiagnosztika: React.FC = () => {
  return (
    <Layout
      allowTransparentHeader={true}
      variant="marketing"
      title="Személyre szabott diagnosztika"
      description="Személyre szabott diagnosztika"
    >
      <Wrapper>
        <Inner>
          <div
            css={css`
              grid-area: txt;
            `}
          >
            <StepIndicator step={1} />
            <H1>
              Személyre szabott <span>diagnosztika</span>
            </H1>
            <p>
              Adatelemzés nélkül nem készülhet jó stratégia, ezért
              szolgáltatásunk első eleme a cége piaci környezetének átfogó
              elemzésével kezdődik, mely során megvizsgáljuk a szervezet –
              üzletág – marketing és üzleti környezetét, céljait, eddig
              megfogalmazott és alkalmazott stratégiáit és tevékenységeit, a
              rendelkezésre álló erőforrásokat azért, hogy feltárjuk a
              problematikus területeket, és javaslatokat fogalmazzunk meg a
              marketing teljesítmény javítására. Mindez természetesen magába
              foglalja például az adott üzletág főbb piaci és versenyhatásainak
              elemzését, valamint az egyes lehetőségek feltárását is, amelyek
              stratégia szempontból a növekedés kulcstényezői lehetnek. Az audit
              önmagában lehetőséget kínál, hogy a vállalat egy új és teljeskörű
              mutatószám rendszert kapjon, mely a későbbi vezetői döntéshozatal
              pillére lehet.
            </p>
          </div>
          <div
            css={css`
              grid-area: card;
            `}
          >
            <Card>
              <h2>Módszereink</h2>
              <ul style={{ padding: 0 }}>
                <Li>Versenytárselemzés (Iparági konkurencia térkép)</Li>
                <Li>Piacelemzés (PESTEL is)</Li>
                <Li>Ügyféligény felmérés</Li>
                <Li>Elégedettségmérés</Li>
                <Li>Értékesítési lehetőségek felmérése</Li>
                <Li>
                  Értékesítési folyamatok feltérképezése/hatékonyság mérés
                  (TOP-DOWN folyamatábra, értéklánc modell)
                </Li>
                <Li>Szervezeti átvilágítás</Li>
              </ul>
            </Card>
            {/* <div /> */}
          </div>
          <PrevNext
            css={css`
              grid-area: nav;
            `}
          >
            {/* <PrevStep step={1} name={"Személyre szabott diagnosztika"} /> */}
            <div />
            <NextStep step={2} name={"Növekedési stratégia kidolgozása"} />
          </PrevNext>
        </Inner>
      </Wrapper>
      <Contact variant="marketing" />
    </Layout>
  );
};

export default SzemelyreSzabottDiagnosztika;

interface LiProps {
  children?: React.ReactNode;
}
const Li: React.FC<LiProps> = ({ children }) => (
  <li
    css={css`
      list-style: none;
      display: grid;
      grid-template-columns: 20px 1fr;
      column-gap: 8px;
      margin-bottom: 16px;
      svg {
        display: inline-block;
        width: 20px;
        height: 20px;
        color: var(--color-primary);
        fill: none;
      }
      p {
        display: inline-block;
      }
    `}
  >
    <FiCheckCircle />
    <p>{children}</p>
  </li>
);

const Wrapper = styled(Container)`
  margin: 160px 0 96px 0;
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 400px;

  grid-template-areas:
    "txt card"
    "nav card";
  column-gap: 32px;
  row-gap: 64px;

  h2 {
    font-weight: 600;
    margin-bottom: 16px;
  }

  @media (max-width: 1310px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "txt"
      "card"
      "nav";
  }
`;

const PrevNext = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 32px;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;
