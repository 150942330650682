import React, { ReactNode } from "react";
import styled from "@emotion/styled";

interface Props {
  children?: ReactNode;
}

const Card: React.FC<Props> = ({ children }) => {
  return (
    <CardBox>
      <Decoration />
      {children}
    </CardBox>
  );
};

export default Card;

export const CardBox = styled.div`
  position: relative;
  overflow: hidden;
  background: white;
  width: 100%;
  padding: 32px;
  box-shadow: var(--shadow);
  border-radius: 20px;

  svg {
    width: 50px;
    height: 50px;
    fill: blue;
  }
`;

const Decoration = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 200px;
  border-radius: 100%;
  background: hsla(210, 100%, 45%, 0.08);
  transform: translate(-40%, -40%);
`;
